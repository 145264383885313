<template>
  <div class="page">
    <div class="main">
      <el-row type="flex" align="middle">
        <el-col :span="2" class="head">
          <el-avatar :size="50" src=""></el-avatar>
        </el-col>
      </el-row>
      <el-row v-for="(item, i) in list" :key="i">
        <el-col :offset="2" :span="3" class="news">
          {{ item.type == 1 ? "学生消息" : "导师回复" }}
        </el-col>
        <el-col :offset="2" :span="10" class="news">
          {{ item.content }}
        </el-col>
        <el-col :offset="2" :span="5" class="news">
          {{ item.create_time }}
        </el-col>
        <el-divider class="test"></el-divider>
      </el-row>

      <el-row style="margin-top: 10px">
        <el-col :offset="2" :span="18">
          <el-input type="textarea" placeholder="请输入内容" v-model="textarea" maxlength="30" show-word-limit>
          </el-input>
        </el-col>
        <el-col :offset="2" :span="2">
          <el-button type="primary" size="small" @click="add()">确认回复</el-button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
//time: 2021/1/25
export default {
  name: "News",
  data() {
    return {
      user_id: "",
      ExpertInfo: [],
      list: [],
      textarea: "",
    };
  },
  created() {
    (this.user_id = this.$route.query.user_id),
      (this.ExpertInfo = JSON.parse(localStorage.getItem("ExpertInfo")));
    this.getList();
  },
  methods: {
    getList() {
      const data = {
        expert_id: this.ExpertInfo.id,
        user_id: this.user_id,
      };
      this.$fecth.post("user_message/getUserMessage", data).then((res) => {
        const { code, msg, data } = res;
        if (code == 200) {
          this.list = data;
          // console.log(data);
        } else {
          this.showTips(0, msg);
        }
      });
    },
    add() {
      const data = {
        expert_id: this.ExpertInfo.id,
        user_id: this.user_id,
        content: this.textarea,
        type: 2,
      };
      this.$fecth.post("user_message/add", data).then((res) => {
        const { code, msg } = res;
        if (code == 200) {
          this.showTips(1, "回复成功");
          this.getList();
        } else {
          this.showTips(0, msg);
        }
      });
    },
    showTips(flag, msg) {
      const title = flag == 1 ? "成功" : "失败";
      const type = flag == 1 ? "success" : "error";
      this.$notify({
        title: title,
        message: msg,
        type: type,
        duration: 2000,
      });
    },
  },
};
</script>
<style lang="less">
.test {
  margin: 33px 6px 3px 11px;
}
</style>

<style lang="less" scoped>
.head {
  text-align: center;
}

.news {
  /*font-size: 14px;*/
  line-height: 30px;
}
</style>